import React from 'react';

function Loading() {
  return (
    <div style={{ backgroundColor: "black", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
      <lottie-player
        src="https://lottie.host/94f1fe39-190f-42e5-b24e-265ca6363784/xz94XlqKp2.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
}

export default Loading;
